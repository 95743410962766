export enum ProductTypes {
  physical = 'Physical Product',
  Invoice = "Invoice",
  Collection = "Collection",
  Event = 'Event',
  quickPayment = 'Quick Payment',
  quickCash = 'Quick Cash',
  subscripe = "Subscription",
  customPayment = "Custom Payment",
  quickQassatly = "Quick Qassatly",
  fawryPayout = "Fawry Payout",
  calendar = "Calendar",
  Reservation="Reservation",
}

export enum ProductTypesAr {
  physical = 'المنتج المادى',
  Invoice = "الفاتورة",
  Collection = "التحصيل",
  Event = 'الفعاليات',
  quickPayment = 'الدفع السريع',
  quickCash = 'النقد السريع',
  subscripe = "الاشتراك",
  customPayment = "الدفع المخصص",
  quickQassatly = "خدمة قسّطلي السريعة",
  fawryPayout = "المصروفات", //not sure of the Arabic name yet
  calendar = "النتيجة",
  Reservation="الحجز",

}

const MainProductRoute = '/seller/new-products'
export const ProductRoutes: any = {
  'Physical Product': `${MainProductRoute}/physical`,
  "Invoice": `${MainProductRoute}/invoice`,
  "Collection": `${MainProductRoute}/claim`,
  'Event': `${MainProductRoute}/event`,
  'Quick Payment': `${MainProductRoute}/quick-payment`,
  'Quick Cash': `${MainProductRoute}/quick-cash`,
  'Subscription': `${MainProductRoute}/subscription`,
  'Quick Qassatly': `${MainProductRoute}/quick-qassatly`,
  'Calendar': `${MainProductRoute}/calendar`,
  'Reservation': `${MainProductRoute}/reservation`,
  "المنتج المادى": `${MainProductRoute}/physical`,
  "الفاتورة": `${MainProductRoute}/invoice`,
  "الفعاليات": `${MainProductRoute}/event`,
  "الدفع السريع": `${MainProductRoute}/quick-payment`,
  "النقد السريع": `${MainProductRoute}/quick-cash`,
  "الاشتراك": `${MainProductRoute}/subscription`,
  "النتيجه": `${MainProductRoute}/calendar`,
  "الحجز":`${MainProductRoute}/reservation`,
}

<div class="form-group">
  <mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ translateFieldName(fieldObject) }}</mat-label>
  <ng-container *ngIf="dateTimeWithHoursOnly; then dateTimeHourOnly; else dateRange"></ng-container>
  <ng-template #dateRange>
  <input [owlDateTime]="dtRange1" [owlDateTimeTrigger]="dtRange1" [selectMode]="'range'"
         [placeholder]="translateFieldName(fieldObject)"
         class="form-control" [formControl]="formControlRef"
         (dateTimeChange)="handleChange($event)" matInput>
  <owl-date-time #dtRange1></owl-date-time>
  </ng-template>
  <ng-template #dateTimeHourOnly>
  <input [min]="nextHour" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
         [placeholder]="translateFieldName(fieldObject)"
         class="form-control" [formControl]="formControlRef"
         (dateTimeChange)="handleChange($event)" matInput>
  <owl-date-time #dt1 [stepMinute]="60" [hour12Timer] = "'true'" [startAt] = "startAt"></owl-date-time>
  </ng-template>
  <mat-error class="text-danger"
         *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>
</mat-form-field>
</div>
